<app-layout [title]="product.name" *ngIf="product">

  <div id="details-container">
    <div class="project-name">
      <h4 class="caption">Project</h4>
      <ul class="details-list">
        <li>{{ product.title.rendered }} xdd</li>
      </ul>
    </div>
    <div class="details">
      <h4 class="caption">Scope</h4>
      <ul class="details-list">
        <li>Corporate <span class="serif">Branding</span></li>
        <li><span class="serif">Visual</span> language</li>
        <li>Website design</li>
      </ul>
    </div>
    <div class="details">
      <h4 class="caption">Created</h4>
      <ul class="details-list">
        <li><span class="serif">{{ product.year }}</span></li>
      </ul>
    </div>
  </div>

  {{ product | json }}
  {{ product.id }}

  <div style="border: 1px solid red" [innerHTML]="product.content.rendered">
  </div>

<!--  <ng-container *ngIf="product.id === 'cluster'">-->
<!--    <ng-container *ngTemplateOutlet="cluster"></ng-container>-->
<!--  </ng-container>-->

<!--  <ng-container *ngIf="product.id === 'nexwork'">-->
<!--    <ng-container *ngTemplateOutlet="nexwork"></ng-container>-->
<!--  </ng-container>-->

<!--  <ng-container *ngIf="product.id === 'honeyboy'">-->
<!--    <ng-container *ngTemplateOutlet="honeyboy"></ng-container>-->
<!--  </ng-container>-->

<!--  <ng-container *ngIf="product.id === 'mbank'">-->
<!--    <ng-container *ngTemplateOutlet="mbank"></ng-container>-->
<!--  </ng-container>-->


<!--  <ng-container *ngIf="product.id === 'logos-and-marks'">-->
<!--    <ng-container *ngTemplateOutlet="logosAndMarks"></ng-container>-->
<!--  </ng-container>-->

<!--  <ng-container *ngIf="product.id === 'brands-guru-24'">-->
<!--    <ng-container *ngTemplateOutlet="brandsGuru24"></ng-container>-->
<!--  </ng-container>-->



</app-layout>

<!--&lt;!&ndash;Cluster&ndash;&gt;-->
<!--<ng-template #cluster>-->

<!--  <div class="bottom40"><img src="assets/projects/cluster/header.png" width="100%"></div>-->

<!--  <div id="description" class="proj-margins bottom40">-->
<!--    <div id="more-links" class="left">-->
<!--      <p class="caption">More</p>-->
<!--      <ul>-->
<!--        <li><a href="http://clusteroffices.com" target="_blank">Visit website</a></li>-->
<!--        <br>-->
<!--        <li><a href="https://www.behance.net/gallery/125035097/Cluster-Offices" target="_blank">Behance Presentation</a>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
<!--    <div id="description-details" class="right">-->
<!--      <p class="caption">Challenge</p>-->
<!--      <p class="bottom80 serif no-uppercase">Buildings of coworking spaces are totally different from eachother. The-->
<!--        biggest challenge is to find a visual language which can fit into different spaces at the same time making them-->
<!--        choesive with eachother.</p>-->
<!--      <p class="caption">Sollution</p>-->
<!--      <p class="bottom80 serif no-uppercase">Broad palette with simple minimal geometrical form is very modular and-->
<!--        adaptable.</p>-->
<!--      <p class="caption">Results</p>-->
<!--      <div class="big-number-container">-->
<!--        <p class="big-number">115<span class="serif">%</span></p>-->
<!--        <p class="big-number-description serif no-uppercase">Higher conversion rate</p>-->
<!--      </div>-->
<!--      <div class="big-number-container">-->
<!--        <p class="big-number">23<span class="serif">%</span></p>-->
<!--        <p class="big-number-description serif no-uppercase">Increase of website visits</p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="clear"></div>-->
<!--  </div>-->

<!--  <div id="case" style="background-color: #F6F6F6;">-->
<!--    <div class="proj-left bottom40">-->
<!--      <img class="images-row" src="assets/projects/cluster/locations-1.png" width="45%"><img class="images-row"-->
<!--                                                                                             src="assets/projects/cluster/locations-2.png"-->
<!--                                                                                             width="45%">-->
<!--    </div>-->
<!--    <div class="proj-right bottom40"><img src="assets/projects/cluster/palette.png" width="100%"></div>-->
<!--    <div class="bottom40"><img src="assets/projects/cluster/home.png" width="100%"></div>-->
<!--    <div class="proj-left bottom40"><img src="assets/projects/cluster/location.png" width="100%"></div>-->
<!--    <div class="proj-right bottom40"><img src="assets/projects/cluster/flex.png" width="100%"></div>-->
<!--    <div class="proj-left bottom40"><img src="assets/projects/cluster/mobile.png" width="100%"></div>-->
<!--    <div class="bottom40"><img src="assets/projects/cluster/call-footer.png" width="100%"></div>-->
<!--    <div class="proj-margins bottom40"><img src="assets/projects/cluster/deck.png" width="100%"></div>-->
<!--  </div>-->
<!--</ng-template>-->

<!--&lt;!&ndash;Nexwork&ndash;&gt;-->
<!--<ng-template #nexwork>-->
<!--  <div id="case" style="background-color: #F6F6F6;">-->
<!--    <div class="proj-left bottom40"><img class="images-row" src="assets/projects/nexwork/01.png" width="100%"></div>-->
<!--    <div class="proj-right bottom40"><img src="assets/projects/nexwork/02.png" width="100%"></div>-->
<!--    <div class="bottom40"><img src="assets/projects/nexwork/03.png" width="100%"></div>-->
<!--    <div class="proj-left bottom40"><img src="assets/projects/nexwork/04.png" width="100%"></div>-->
<!--    <div class="proj-right bottom40"><img src="assets/projects/nexwork/05.png" width="100%"></div>-->
<!--    <div class="proj-left bottom40"><img src="assets/projects/nexwork/07.png" width="100%"></div>-->
<!--    <div class="bottom40"><img src="assets/projects/nexwork/08.png" width="100%"></div>-->
<!--    <div class="proj-margins bottom40"><img src="assets/projects/nexwork/09.png" width="100%"></div>-->
<!--    <div class="proj-margins">-->
<!--      <p class="caption">special thanks</p>-->
<!--      <p class="serif no-uppercase" style="font-size: 3vh;">To ICONS8 and <a href="https://saramaese.com/"-->
<!--                                                                             target="_blank">Sara maese</a> for creating<br>such-->
<!--        a beautiful illustration kit that could be used.</p>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->

<!--&lt;!&ndash;Honeyboy&ndash;&gt;-->
<!--<ng-template #honeyboy>-->
<!--  <div id="case" style="background-color: #F6F6F6;">-->
<!--    <div class="proj-left bottom40"><img class="images-row" src="assets/projects/honeyboy/01.png" width="100%"></div>-->
<!--    <div class="proj-right bottom40"><img src="assets/projects/honeyboy/02.png" width="100%"></div>-->
<!--    <div class="bottom40"><img src="assets/projects/honeyboy/03.png" width="100%"></div>-->
<!--    <div class="proj-left bottom40"><img src="assets/projects/honeyboy/04.png" width="100%"></div>-->
<!--    <div class="proj-right bottom40"><img src="assets/projects/honeyboy/05.png" width="100%"></div>-->
<!--    <div class="proj-left bottom40"><img class="images-row" src="assets/projects/honeyboy/06.png" width="100%"></div>-->
<!--    <div class="proj-left bottom40"><img class="images-row" src="assets/projects/honeyboy/07.png" width="100%"></div>-->
<!--    <div class=""><img src="assets/projects/honeyboy/08.png" width="100%"></div>-->
<!--    <div class="clear"></div>-->
<!--  </div>-->
<!--</ng-template>-->

<!--&lt;!&ndash;Mbank&ndash;&gt;-->
<!--<ng-template #mbank>-->
<!--  <div id="description" class="proj-margins bottom40">-->
<!--    <div id="more-links" class="left">-->
<!--      <p class="caption">More</p>-->
<!--      <ul>-->
<!--        <li><a href="mbank.pdf">Download raport (Polish)</a></li><br>-->
<!--        <li><a href="https://www.behance.net/gallery/96258357/mBank-Know-Your-Customer" target="_blank">Behance Presentation</a></li>-->
<!--      </ul>-->
<!--    </div>-->
<!--    <div id="description-details" class="right">-->
<!--      <p class="caption">Challenge</p>-->
<!--      <p class="bottom80 serif no-uppercase">mBank account creation process was not only unefficient but also very analog. Company is strongly promoting their mobile banking and limiting availability of sign up process only to desktop was a huge drop-out reason.<br><br>Moreover process required signing up the contract delivered directly to your door by courier which took several days to be able to use banking account and platform.</p>-->
<!--      <p class="caption">Sollution</p>-->
<!--      <p class="bottom80 serif no-uppercase">Using scanning documents, comparing them with photos and text recognition algorithms we were able to confirm user identity and pre-fill all important data in the form making it as efortless and fast as possible</p>-->
<!--      <p class="caption">Results</p>-->
<!--      <div class="big-number-container">-->
<!--        <p class="big-number">31<span class="serif">%</span></p>-->
<!--        <p class="big-number-description serif no-uppercase">Less steps when creating account than avg. competition</p>-->
<!--      </div>-->
<!--      <div class="big-number-container">-->
<!--        <p class="big-number">8</p>-->
<!--        <p class="big-number-description serif no-uppercase">Amount of inputs user has to fill in the entire process</p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="clear"></div>-->
<!--  </div>-->
<!--  <div id="case" style="background-color: #F3F4F6;">-->
<!--    <div class="proj-left bottom40"><img class="images-row" src="assets/projects/mbank/01.png" width="100%"></div>-->
<!--    <div class="bottom40"><img src="assets/projects/mbank/02.png" width="100%"></div>-->
<!--    <div class="proj-right bottom40"><img src="assets/projects/mbank/03.png" width="100%"></div>-->
<!--    <div class="proj-left bottom40"><img src="assets/projects/mbank/04.png" width="100%"></div>-->
<!--    <div class="proj-right bottom40"><img src="assets/projects/mbank/05.png" width="100%"></div>-->
<!--    <div class="proj-left bottom40"><img src="assets/projects/mbank/06.png" width="100%"></div>-->
<!--    <div class="bottom40"><img src="assets/projects/mbank/07.png" width="100%"></div>-->
<!--    <div class="proj-margins bottom40"><img src="assets/projects/mbank/08.png" width="100%"></div>-->
<!--  </div>-->
<!--</ng-template>-->

<!--&lt;!&ndash;#logos-and-marks&ndash;&gt;-->
<!--<ng-template #logosAndMarks>-->
<!--  <div class=""><img src="assets/projects/logos-and-marks/header.png" width="100%"></div>-->
<!--  <div id="case" style="background-color: #191919;">-->
<!--    <div class="proj-left bottom40"><img class="images-row" src="assets/projects/logos-and-marks/01.png" width="100%"></div>-->
<!--    <div class="proj-right bottom40"><img src="assets/projects/logos-and-marks/02.png" width="100%"></div>-->
<!--    <div class="bottom40"><img src="assets/projects/logos-and-marks/03.png" width="100%"></div>-->
<!--    <div class="proj-left bottom40"><img src="assets/projects/logos-and-marks/08.png" width="100%"></div>-->
<!--    <div class="proj-right bottom40"><img src="assets/projects/logos-and-marks/05.png" width="100%"></div>-->
<!--    <div class="proj-left bottom40"><img class="images-row" src="assets/projects/logos-and-marks/06.png" width="100%"></div>-->
<!--    <div class="proj-left bottom40"><img class="images-row" src="assets/projects/logos-and-marks/07.png" width="100%"></div>-->
<!--    <div class=""><img src="assets/projects/logos-and-marks/04.png" width="100%"></div>-->
<!--    <div class="clear"></div>-->
<!--  </div>-->

<!--</ng-template>-->

<!--&lt;!&ndash;#brands-guru-24&ndash;&gt;-->
<!--<ng-template #brandsGuru24>-->
<!--  <div class="bottom40"><img src="assets/projects/brands-guru-24/03.png" width="100%"></div>-->
<!--  <div id="description" class="proj-margins bottom40">-->
<!--    <div id="more-links" class="left">-->
<!--      <p class="caption">More</p>-->
<!--      <ul>-->
<!--        <li><a href="https://www.behance.net/gallery/48794615/Brands-Guru" target="_blank">Behance Presentation</a></li>-->
<!--      </ul>-->
<!--    </div>-->
<!--    <div id="description-details" class="right">-->
<!--      <p class="caption">Challenge</p>-->
<!--      <p class="bottom80 serif no-uppercase">Everybody wants to look stylish. However shopping for designer brands and high fashion is often expensive. That's where outlets &amp; second-hands come in handy.<br><br>Outlets and second hands in Poland are often associated with low qulity outfashioned clothes with poor service. Idea is to create an online store that gives a feel of luxury but at the same time is affordable.</p>-->
<!--      <p class="caption">Sollution</p>-->
<!--      <p class="bottom80 serif no-uppercase">Minimalistic approach with earth tone palette is something that is associated with luxury goods and also fits easilym to vast majority of fashion brands.</p>-->

<!--    </div>-->
<!--    <div class="clear"></div>-->
<!--  </div>-->
<!--  <div id="case" style="background-color: #F6F6F6; padding-top: 40px; padding-bottom: 40px;">-->
<!--    <div class="proj-left bottom40"><img class="images-row" src="assets/projects/brands-guru-24/Thumbnail.png" width="100%"></div>-->
<!--    <div class="proj-right bottom40"><img src="assets/projects/brands-guru-24/04.png" width="100%"></div>-->
<!--    <div class="bottom40"><img src="assets/projects/brands-guru-24/05.png" width="100%"></div>-->
<!--    <div class="proj-left bottom40"><img src="assets/projects/brands-guru-24/06.png" width="100%"></div>-->
<!--    <div class="proj-right bottom40"><img src="assets/projects/brands-guru-24/07.png" width="100%"></div>-->
<!--    <div class="clear"></div>-->
<!--  </div>-->

<!--</ng-template>-->
