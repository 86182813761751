<div class="product-card" style="color: red">



  <a [routerLink]="['/products/' + product.slug]">
    <h1>      {{ product.title.rendered }}
    </h1>
    <div class="project left">


      <img [src]="product.image"/>
    </div>
  </a>
</div>

