<a [routerLink]="['/']"><img id="logo" src="assets/logo.svg" /></a>
<div id="top-menu">
  <a href="index.html" class="wow fadeInDown">Narano</a>
  <a href="contact.html" class="wow fadeInDown"><span class="serif">Contact</span></a>
</div>


<div class="layout">

  <div class="container-fluid layout__content">
    <main class="layout__content">
      <ng-content></ng-content>
    </main>
  </div>
</div>



<app-footer></app-footer>
