<app-layout title="Home">

  <div id="hello">
    <div id="slogan">
      <p>At (<span class="serif">Narano</span>™),</p>
      <p>&nbsp;</p>
      <p style="margin-bottom: 0.5vw;">We belive <span class="serif">design</span> is not about looks. It is about
        telling your <span class="serif">story</span>. We are <span class="serif">ghost writers</span> behind that.</p>
    </div>
  </div>


  <div class="clear"></div>

  <div id="projects">
    <app-product-card [product]="product" *ngFor="let product of products$ | async"></app-product-card>
  </div>

</app-layout>
