import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Product {
  name: string;
  id: string;
  year: number;
  image: string;
  url: string;
  description?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  getProducts() {
    return this.http.get<Product[]>('https://client.monada.app/wp-json/wp/v2/posts');
  }

  getProductBySlug(slug: string): Observable<any | null> {
    return this.getProducts()
      .pipe(map((products: any) => {
        const filtered = products.filter(x => x.slug == slug);

        console.log(products);

        if (filtered.length > 0) {
          return filtered[0];
        }

        return null;
      }));
  }
}
